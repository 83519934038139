export function getLoginInfo() {
  const loginInfoJsonStr = localStorage.getItem('loginInfo');

  if (!loginInfoJsonStr) {
    return { email: '', clientId: '' };
  }

  try {
    const loginInfo = JSON.parse(loginInfoJsonStr);

    return { email: loginInfo.email || '', clientId: loginInfo.clientId || '' };
  } catch (e) {
    console.log(e);

    return { email: '', clientId: '' };
  }
}

export function setLoginInfo(loginInfo: { email: string; clientId: string }) {
  try {
    const loginInfoJsonStr = JSON.stringify(loginInfo);

    localStorage.setItem('loginInfo', loginInfoJsonStr);
  } catch (e) {
    console.log(e);
  }
}

import { GridRenderCellParams } from '@mui/x-data-grid';

import { ReportSummaryTabGetAllRes } from '../../../../../api/report/report/types';
import AddressGroupCell from '../../../../../common/components/Table/AddressGroupCell';
import { useGenTableColumns } from '../../../../../hooks/useGenTableColumns';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

function useTableColumns() {
  const renderAddressGroupCell = (
    params: GridRenderCellParams<any, { rawData: ReportSummaryTabGetAllRes }, any>
  ) => {
    const walletGroupId = params.row.rawData['inspectionWallet.inspectionWalletGroup.id'];

    return <AddressGroupCell id={walletGroupId} />;
  };

  const { columns } = useGenTableColumns({
    translatePrefix: TRANSLATE_PREFIX,
    columnConfigArr: [
      {
        translateKey: TRANSLATE_KEY.addressGroup,
        minWidth: 130,
        renderCell: renderAddressGroupCell,
      },
      { translateKey: TRANSLATE_KEY.network, minWidth: 130 },
      { translateKey: TRANSLATE_KEY.token, minWidth: 130 },

      { translateKey: TRANSLATE_KEY.openingBalance, minWidth: 170 },
      { translateKey: TRANSLATE_KEY.inAmount, minWidth: 170 },
      { translateKey: TRANSLATE_KEY.outAmount, minWidth: 170 },
      { translateKey: TRANSLATE_KEY.txFee, minWidth: 170 },
      { translateKey: TRANSLATE_KEY.closingBalance, minWidth: 170 },
    ],
  });

  return { columns };
}

export default useTableColumns;

import { useState } from 'react';
import { useQuery } from 'react-query';

import APIs from '../api';
import {
  setClientSupportedAssetChainCodeMapping,
  setMerchantSupportedAssetListAndChainCodeMapping,
  setShouldShowFirstLoginPage
} from '../reducer/assetSlice';
import { useAppDispatch } from '../reducer/hooks';

export const useGetSupportedAsset = ({ shouldFetch }: { shouldFetch: boolean }) => {
  const dispatch = useAppDispatch();

  const [isFetchingSupportedAsset, setIsFetchingSupportedAsset] = useState(true);

  useQuery({
    enabled: shouldFetch,
    queryKey: 'getSupportedAsset',
    queryFn: APIs.query.asset.getMerchantSupportedAsset,
    onError: () => setIsFetchingSupportedAsset(false),
    // onError: () => dispatch(setIsFetchingSupportedAsset(false)),
    onSuccess: async (data) => {
      if (!data) {
        setIsFetchingSupportedAsset(false);
        return;
      }

      dispatch(setMerchantSupportedAssetListAndChainCodeMapping(data));

      const res = await APIs.query.asset.getClientSupportedAsset();

      setIsFetchingSupportedAsset(false);
      // dispatch(setIsFetchingSupportedAsset(false));

      if (!res) {
        return;
      }

      if (!res.length) {
        dispatch(setShouldShowFirstLoginPage(true));
      } else {
        dispatch(setShouldShowFirstLoginPage(false));
      }

      dispatch(setClientSupportedAssetChainCodeMapping(res));
    },
  });

  return { isFetchingSupportedAsset };
};

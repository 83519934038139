import './index.css';

import React, { useEffect, useState } from 'react';

import { ENDPOINT_HOST } from '../../../utils/config';
import { getLoginInfo } from './helpers/getSetLoginInfo';
import EmailVerification from './Pages/EmailVerification';
import EnterEmail from './Pages/EnterEmail';
import ForgotPassword from './Pages/EnterForgotPasswordEmail';
import EnterPassword from './Pages/EnterPassword';
import EnterPasswordWithVerify from './Pages/EnterPasswordWithVerify';
import ResetPassword from './Pages/ResetPassword';
import {
  LoginFields,
  LoginFieldsKeys,
  LoginPageShareContext,
  PageKeys
} from './types';

const MERCHANT_ID = ENDPOINT_HOST === 'http://18.143.36.162:8097' ? '3' : '1';

const initFields: LoginFields = {
  email: '',
  password: '',
  emailVerificationCode: '',
  newPassword: '',
  confirmNewPassword: '',
  clientId: '',
};

const initPageShareContext = {
  isDeviceRegistered: false,
};

interface LoginContextProps {
  merchantId: string;
  fields: LoginFields;
  setFields: React.Dispatch<React.SetStateAction<LoginFields>>;
  pageShareContext: LoginPageShareContext;
  setPageShareContext: React.Dispatch<React.SetStateAction<LoginPageShareContext>>;
  page: PageKeys;
  setPage: React.Dispatch<React.SetStateAction<PageKeys>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  reset: () => void;
}

export const LoginContext = React.createContext<LoginContextProps>({
  merchantId: MERCHANT_ID,
  fields: initFields,
  setFields: () => {},
  pageShareContext: initPageShareContext,
  setPageShareContext: () => {},
  page: PageKeys.EnterEmail,
  setPage: () => {},
  isLoading: false,
  setIsLoading: () => {},
  reset: () => {},
});

const IndividualLogin = () => {
  const { email, clientId } = getLoginInfo();

  const initFieldsWithCachedLoginInfo = { ...initFields, email, clientId };

  const [fields, setFields] = useState<LoginFields>(initFieldsWithCachedLoginInfo);
  const [pageShareContext, setPageShareContext] =
    useState<LoginPageShareContext>(initPageShareContext);
  const [page, setPage] = useState(PageKeys.EnterEmail);
  const [isLoading, setIsLoading] = useState(false);

  console.log('[DEBUG] IndividualLogin', {
    fields,
  });

  // reset fields and page
  const reset = () => {
    setFields(initFieldsWithCachedLoginInfo);
    setPage(PageKeys.EnterEmail);
  };

  // reset email verification code when page changes
  useEffect(() => {
    setFields({ ...fields, [LoginFieldsKeys.EmailVerificationCode]: '' });
  }, [page]);

  const LoginContextValue = {
    merchantId: MERCHANT_ID,
    fields,
    setFields,
    pageShareContext,
    setPageShareContext,
    page,
    setPage,
    reset,
    isLoading,
    setIsLoading,
  };

  const PageMap = {
    [PageKeys.EnterEmail]: <EnterEmail />,
    // [PageKeys.EnterEmail]: <></>,
    [PageKeys.EmailVerification]: <EmailVerification />,
    [PageKeys.ResetPassword]: <ResetPassword />,
    [PageKeys.EnterPassword]: <EnterPassword />,
    [PageKeys.EnterPasswordWithVerify]: <EnterPasswordWithVerify />,
    [PageKeys.ForgotPassword]: <ForgotPassword />,
  };

  return <LoginContext.Provider value={LoginContextValue}>{PageMap[page]}</LoginContext.Provider>;
};

export default IndividualLogin;

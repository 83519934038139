import errorCode from '../../utils/errorCode.json';
import COUNTRY_CODE_LIST from './countryCodeList.json';

const json = {
  noPrefix: {}, // for notice no prefix set when use zustand translate prefix
  errorCode: errorCode.en,
  countryCodes: COUNTRY_CODE_LIST, // Reference: https://github.com/joonhocho/country-calling-code

  menu: {
    version: 'Version',
    homepage: 'Home',
    accountSetting: 'Account Setting',
    changePassword: 'Change Password',
    toggleFilterButtonCollapse: 'Collapse',
    toggleFilterButtonExpand: 'Expand',
    toggleBtnCollapse: 'Collapse',
    toggleBtnExpand: 'Expand',
    dashboard: 'Dashboard',

    // category
    addressManagement: 'Address Management',
    report: 'Report',
    // sub-category

    asset: 'Asset',
    addressGroup: 'Address Group',
    addressList: 'Address List',
    addressBalance: 'Address Balance',

    transaction: 'Transaction',

    downloadAppButton: 'Download App',
  },

  common: {
    welcome: 'Welcome',
    logout: 'Logout',
    confirm: 'Confirm',
    edit: 'Edit',
    cancel: 'Cancel',
    start_date: 'Start Date',
    end_date: 'End Date',
    no_data_export: 'No data for export',
    phSelection: 'Select {{fieldName}}',
    phInputField: 'Enter {{fieldName}}',
    empty_selection_notice: 'Please select {{fieldName}}',
    empty_input_field_notice: 'Please enter {{fieldName}}',
    input_field_check_number: 'Please enter valid {{fieldName}}',
    phSelectionOptional: 'Please select {{fieldName}} (Optional)',
    phInputFieldOptional: 'Please enter {{fieldName}} (Optional)',
    search: 'Search',
    reset: 'Reset',
    export: 'Export',
    table: 'Table',
    successCreate: 'Created {{target} Successfully',
    autoLogoutAlert:
      'Automatically logged out because another account is already logged in from same browser',
    logoutAlert: 'You have been logged out',
    alert_no_permission: 'No relevant permission',
    programName: 'Program Name',
    program_name: 'Program Name',
    question_type: 'Question Type',
    idv_method: 'IDV Method',
    customerId: 'Customer Id',
    status: 'Status',
    customerNumber: 'Customer Number',
    merchantId: 'Merchant Id',
    createdBy: 'Created By',
    creationDate: 'Created Date',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    lastModifiedDate: 'Last Modified Date',
    latestVersion: 'Latest Version',
    applicationNumber: 'Application Number',
    tcspCustomerApplicationType: 'TCSP Customer Application Type',
    txHash: 'Transaction Hash',
    referenceNumber: 'Reference Number',
    currency: 'Currency',
    approvalProgressDialogTitle: 'Approval Progress',
    no_relevant_permission: 'No relevant permission',
    no_same_operator_creator: 'Operator and creator cannot be the same',
    no_same_approver: 'Approver and operator cannot be the same',
    request_approved: 'Request Approved',
    approve_successful: 'Approve Successful',
    request_rejected: 'Request Rejected',
    remarks: 'Remarks',
    confirm_btn: 'Confirm',
    approve_btn: 'Approve',
    reject_btn: 'Reject',
    approve_reject_request_btn: 'Approve/Reject',
    viewDetail: 'View Detail',
    approve_reject_dialog_title: 'Approve/Reject ',
    chainName: 'Chain Name',
    selectSearchLabel: 'Type to search',
    orderId: 'Order Id',
    transactionType: 'Transaction Type',
    amount: 'Amount',
    sendingAddress: 'Sending Address',
    receivingAddress: 'Receiving Address',
    creditAmount: 'Credit Amount',
    exchangeRate: 'Exchange Rate',
    transactionHash: 'Transaction Hash',
    creationTime: 'Creation Time',
    cancel_btn: 'Cancel',
    approveReject: 'Approve/Reject',
    applicationDetails: 'Application Details',
    close: 'Close',
    no_data_found: 'No Data in Database',
    auto: 'Auto',
    create_request: 'Create Request',
    not_allow_to_pick: 'Not allow to pick {{object}',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    create_request_success: 'Created request successfully',
    create_record_success: 'Created record successfully',
    copied: 'Copied!',
    clientId: 'Client Id',
    create_record: 'Create Record',
    adjustmentAmount: 'Adjustment Amount',
    ph_program_name_add_on: 'for viewing options in Asset',
    createRecord: 'Create Record',
    validate_below_zero: '{{fieldName}} must be larger than 0',
    validate_zero: '{{fieldName}} must not be 0',
    no_changes_made_warning: 'Please modify',
    partnerName: 'Partner Name',
    homeCurrency: 'Home Currency',
    adjustmentMode: 'Adjustment Mode',
    customer_number_not_found: 'Customer No. does not exist',
    successful_edit: 'Edited successfully',
    repaymentCurrency: 'Repayment Currency',
    customerLevel: 'Customer Level',
    rewardRate: 'Reward Rate',
    editRecord: 'Edit Record',
    edit_btn: 'Edit',
    request_record_created: 'Request record created',
    network: 'Network',
    yes: 'Yes',
    no: 'No',
    next: 'next',
    optional_field: '{{fieldName} (Optional)',
    //
    ledgerClientId: 'Ledger Client Id',
    pfhNumber: 'PFH Number',
    lastLedgerTransactionId: 'Last Ledger Transaction Id',
    fromCurrency: 'From Currency',
    toCurrency: 'To Currency',
    distributorAgentId: 'Distributor Agent Id',
    agentType: 'Agent Type',
    agentRole: 'Agent Role',
    agentStatus: 'Agent Status',
    agentLoginEmail: 'Login Email',
    roles: 'Roles',
    noRecord: 'No Record',
    month: 'Month',
    transactionAmount: 'Transaction Amount',
    cannot_exceed_warning: '{{fieldName} cannot exceed {{value}}',
    agentName: 'Name',

    rebateTaskStatus: 'Rebate Status',
    rebateStatus: 'Rebate Status',

    taskId: 'Task ID',

    to: 'To',
    from: 'From',
    rebateMode: 'Rebate Mode',
    download: 'Download',

    am: 'AM',
    pm: 'PM',
    programNameDisplay: 'Program Name (Display)',
    tokenNotFound: 'Token not found',
    display_records_limit: 'Display max. {{limit}} records',

    programDisplayName: 'Program Display Name',
    questionType: 'Question Type',
    kycIdvMethod: 'IDV Method',
    programCurrency: 'Program Currency',
    assignedTo: 'Assigned To',
    ph_search_bar: 'Search {{fieldName}}',
    edit_success_message: 'Edited {{itemName}} Successfully',
    update_success_message: 'Updated {{itemName}} Successfully',
    create_success_message: 'Created New {{itemName}} Successfully',
    add_success_message: 'Added {{itemName}} Successfully',
    delete_success_message: 'Deleted {{itemName}} Successfully',
    multiple_selections_label: '{{fieldName}} (Multiple Selections)',
    multiple_input_label: '{{fieldName}} (Multiple Inputs)',
    not_available: 'N/A',
    confirm_delete_notice: 'Confirm to Delete?',
    internal_error_message: 'Internal Error',
    remove_this_item: 'Remove this {{itemName}}',
  },

  dashboard: {
    network: 'Network',
    qrCode: 'QR Code',
    walletAddress: 'Wallet Address',
    hkdmBalanceTitle: 'HKDM Balance of Program Agent',
    hkdm: 'HKDM',
    hkd: 'HKD',
    distributionOverview: 'Distribution Overview',
    totalNoOfDistributorAgent: 'Total No. of Distributor Agent',
    totalNoOfClient: 'Total No. of Client',
    totalNoOfActiveAgent: 'Total No. of Active Agent',
    totalNoOfActiveAgentSubtitle:
      'Display Total No. of Agent That Created At Least One Client Application Within 30 Days',
    totalNoOfActiveClient: 'Total No. of Active Client',
    totalNoOfActiveClientSubtitle:
      'Display Total No. of Client That Created At Least one Card Transaction Within 30 Days',
    programAgentOverview: 'Program Agent Overview',
    totalCustodyAssetTop5: 'Total Custody Asset (Top 5)',
    totalRebateAmount: 'Total Rebate Amount',
    totalPurchaseAmount: 'Total Purchase Amount',
    SevenDay: 'Last 7 Days',
    FourteenDay: 'Last 14 Days',
    ThirtyDay: 'Last 30 Days',
    assetOverview: 'Asset Overview',
    totalProgramNumber: 'Total No. of Program',
    viewDetails: 'View Details',
  },

  programAgentDeposit: {
    programName: 'Program Name',
    currency: 'Currency',
    qrCode: 'QR Code',
    walletAddress: 'Wallet Address',
  },

  login: {
    email: 'Email',
    password: 'Password',
    send: 'Send',
    resend: 'Resend',
    emailNotExistError: 'Email Address does not exist',
    programAgentId: 'Program Agent Id',
    enterEmail: {
      companyEmail: 'Company Email',
      clientId: 'Client ID',
    },
    emailVerification: {
      verificationCode: 'Verification Code',
      enterVerificationCode: 'Enter Verification Code',
    },
    resetPassword: {
      successResetAlert: 'Successfully reset password',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      characterLimit: '8-40 characters',
      requiredUpperCase: 'At least 1 uppercase letter',
      requiredSymbol: 'At least 1 symbol',
      requiredNumber: 'At least 1 number',
    },
    enterPassword: {
      password: 'Password',
    },
    enterPasswordWithVerify: {
      trustDevice: 'Trust this device',
      verificationCodeCardTitle: 'Verifying Your Device',
      verificationCodeCardSubtitle: 'Click “Send” to send verification code to your email address',
    },
    forgotPassword: 'Forgot Password?',
    enterForgotPasswordEmail: {
      title: 'Forgot Password?',
      email: 'Email Address',
      enterVerifiedEmail: 'Please enter the registered email to receive email verification code',
      emailNotFound: 'Email Address does not exist',
    },
  },

  validation: {
    selectRequired: 'Please select {{fieldName}}',
    inputRequired: 'Please enter {{fieldName}}',
    shouldBeLargerThanZero: '{{fieldName}} must be larger than 0',
    requiredLength: '{{fieldName}} must be between {{min} to {{max} characters',
    requiredUpperCase: '{{fieldName}} must contain at least {{min} uppercase letter',
    requiredSymbol: 'Password must contain at least 1 symbol',
    requiredContainNumber: '{{fieldName}} must contain at least {{min}} number',
    confirmPassword: 'Password and Confirm Password must be the same',
    loginEmailInvalid: 'Invalid email address format',
  },

  component: {
    typeToSearch: 'Type to Search',
    noRecords: 'No relevant record exists',
    itemTotalStart: '',
    itemTotalEnd: ' item(s) in total',
    clickSearchToViewRecords: 'Click search to view records',
    apply_filter_to_view_records: 'Apply filter to view records',
    startMonth: 'Start Month',
    endMonth: 'End Month',
    no_matching_result: 'No Matching Result',
    clear_all: 'Clear All',
  },

  commonComponent: {
    filter: {
      address_group: 'Address Group',
      token: 'Token',
      network: 'Network',
    },
  },

  accountSetting: {
    email: 'Login Email',
    timezone: 'Time Zone',
    language: 'Language',
    password: 'Password',
    changePassword: 'Change Password',

    creationTime: 'Creation Time',
    programName: 'Program Name',
    programDisplayName: 'Program Display Name',
    kycIdvMethod: 'IDV Method',
    questionType: 'Question Type',
    programCurrency: 'Program Currency',
    assignedTo: 'Assigned To',
    lastModifiedTime: 'Last Modified Time',
  },
  changePassword: {
    changePassword: 'Change Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm New Password',
    changedSuccess: 'Password changed',
    passwordRequirements:
      'Password must contain 8-40 characters, at least 1 uppercase letter, at least 1 symbol, and at least 1 number',
    characterLimit: '8-40 characters',
    requiredUpperCase: 'At least 1 uppercase letter',
    requiredSymbol: 'At least 1 symbol',
    requiredNumber: 'At least 1 number',
  },

  enumConstants: {
    en: 'English',
    zh_cn: '繁中',

    timezone0: '+0',
    timezone1: '+1',
    timezone2: '+2',
    timezone3: '+3',
    timezone4: '+4',
    timezone5: '+5',
    timezone6: '+6',
    timezone7: '+7',
    timezone8: '+8',
    timezone9: '+9',
    timezone10: '+10',
    timezone11: '+11',
    timezone12: '+12',
    timezone13: '-11',
    timezone14: '-10',
    timezone15: '-9',
    timezone16: '-8',
    timezone17: '-7',
    timezone18: '-6',
    timezone19: '-5',
    timezone20: '-4',
    timezone21: '-3',
    timezone22: '-2',
    timezone23: '-1',

    yes: 'Yes',
    no: 'No',
    Yes: 'Yes',
    No: 'No',

    Enabled: 'Enabled',
    Disabled: 'Disabled',
    Enable: 'Enabled',
    Disable: 'Disabled',

    enable: 'Enable',
    disable: 'Disable',
    enabled: 'Enabled',
    disabled: 'Disabled',
  },

  enumRiskLevel: {
    NoData: '-2 No Data',
    NoAssessment: '-1 No Assessment',
    NoRiskDetected: '0 No Risk Detected',
    Info: '1 Info',
    Caution: '2 Caution',
    Medium: '3 Medium',
    High: '4 High',
    Critical: '5 Critical',
  },

  enumMathComparison: {
    EqualTo: 'Equal to',
    LessThan: 'Less than',
    LessThanOrEqualTo: 'Less than or Equal to',
    GreaterThan: 'Greater than',
    GreaterThanOrEqualTo: 'Greater than or Equal to',
  },

  enumDirection: {
    IN: 'In',
    OUT: 'Out',
  },

  enumImportAddressStatus: {
    fail: 'Fail',
    duplicated: 'Duplicated',
    success: 'Success',
  },

  enumTransferTransactionStatus: {
    FastConfirmedFail: 'Fast Confirmed Fail',
    ConfirmFailed: 'Confirmed Fail',
    None: 'None',
    Unconfirmed: 'Unconfirmed',
    Confirmed: 'Confirmed',
    Invalid: 'Invalid',
    FastConfirmed: 'Fast Confirmed',
  },

  userSetting: {
    distributorAgentId: 'Distributor Agent Id',
    agentType: 'Agent Type',
    loginEmail: 'Login Email',
    role: 'Role',
    roles: 'Roles',
    status: 'Status',
    remarks: 'Remarks',
    createdBy: 'Created By',
    creationTime: 'Creation Time',
    lastModifiedBy: 'Last Modified By',
    lastModifiedTime: 'Last Modified Time',
    operations: 'Operations',
    createUser: 'Create User',
    createUserSuccessAlert: 'Created APP user successfully',
    edit: 'Edit',
    editUser: 'Edit User',
    editUserSuccessAlert: 'Edited successfully',
    operation: 'Operations',
    name: 'Name',
  },

  searchBar: {
    transaction: 'Transaction',
    address_group: 'Address Group',
    report: 'Report',
    address_list: 'Address List',
    no_db_data: 'No {{itemName}}',
    no_search_result: 'No Relevant {{itemName}}',
    search: 'Search',
  },

  addressList: {
    add_address: 'Add Address',
    import: 'Import',
    template: 'Template',

    operation: 'Operation',
    display_name: 'Display Name',
    address_group: 'Address Group',
    address: 'Address',
    network: 'Network',
    add_address_date: 'Add Address Date',

    edit_address: 'Edit Address',
    address_balance: 'Address Balance',
    report: 'Report',

    import_address_format_error_message: 'Please use the format in the Template',
    import_address_network_error_message:
      'The imported addresses do not match the selected assets.',
    import_address_address_group_error_message:
      'No address group created. Please create a group before importing.',

    dialog: {
      address: 'Address',
      add_address: 'Add Address',
      network: 'Network',
      asset: 'Asset',

      address_group: 'Address Group',
      display_name: 'Display Name',
      edit_address: 'Edit Address',
      delete_address: 'Delete Address',
      network_notice_text: 'Please ensure the selected Network can view the address assets',
      not_supported_asset_notice: 'Please choose asset located on "Asset" page',
      address_group_notice_text: 'Change or Add a Group',

      index: 'Index',
      status: 'Status',
      error_message: 'Error Message',
      import_address_result: 'Import Address Result',
      fail_records: 'Fail Records',
      duplicated_count: 'Duplicated Count',
      success_count: 'Success Count',
      download_log_file: 'Download Log File',
    },
  },
  addressBalance: {
    operation: 'Operation',
    display_name: 'Display Name',
    address_group: 'Address Group',
    address: 'Address',
    network: 'Network',
    token: 'Token',
    address_balance: 'Address Balance',
    amount: 'Amount',
    exclude_zero: 'Exclude Zero',
    settlement_time: 'Settlement Time',
    more_than: 'More Than',
    less_than: 'Less Than',
    equal_to: 'Equal To',
    latest_checkpoint_id: 'Latest Checkpoint ID',
    latest_checked_block_number: 'Latest Checked Block No.',
    certified_with_on_chain_balance: 'Certified with On-Chain Balance',
    last_certified_checkpoint_id: 'Last Certified Checkpoint ID',
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',

    add_address: 'Add Address',
    edit_address: 'Edit Address',
    import_excel: 'Import Excel',
    excel_template: 'Excel Template',
    asset: 'Asset',
    not_supported_asset_notice: 'Please choose asset located on "Asset" page',
    report: 'Report',
  },

  addressGroup: {
    group: 'Group',
    create_new_group: 'Create New Group',
    edit_group: 'Edit Group',
    view_balance: 'View Balance',
    view_transaction: 'View Transaction',

    dialog: {
      create_new_group: 'Create New Group',
      edit_group: 'Edit Group',
      group: 'Group',
      group_name: 'Group Name',
      choose_color: 'Choose Color',
      choose_shape: 'Choose Shape',
      delete_group: 'Delete Group',
    },
  },

  asset: {
    instruction_text: 'Please select asset',
    token: 'Token',
    token_list: 'Token List',
    network: 'Network',
  },

  homePage: {
    address_name_or_address: 'Address Name / Address',
    total_address: 'Total Address',
    total_address_group: 'Total Address Group',
    daily_settlement_count: 'Daily Settlement Count',
    last_daily_report: 'Last Daily Report',
    utc_notice_text: 'Data record is based on UTC',
  },

  transaction: {
    creation_time: 'Creation Time',
    last_modified_time: 'Last Modified Time',
    settlement_time: 'Settlement Time',
    transaction_hash: 'Transaction Hash',
    address: 'Address',
    display_name: 'Display Name',
    network: 'Network',
    token: 'Token',
    address_group: 'Address Group',
    direction: 'Direction',
    fee_record: 'Fee Record',
    risk_level: 'Risk Level',
    check_point: 'Check Point',
    details: 'Details',
    transaction_time: 'Transaction Time',
    status: 'Status',
    amount: 'Amount',
    sending_address: 'Sending Address',
    receiving_address: 'Receiving Address',
    miner_fee: 'Miner Fee',
    post_balance: 'Post Balance',
    checkpoint_id: 'Checkpoint ID',
    certified_with_on_chain_balance: 'Certified with On-Chain Balance',

    detailsDialog: {
      transaction_details: 'Transaction Details',
      creation_time: 'Creation Time',
      settlement_time: 'Settlement Time',
      miner_fee: 'Miner Fee',
      status: 'Status',
      transaction_amount: 'Transaction Amount',
      send: 'Send',
      receive: 'Receive',
      chain_information: 'Chain Information',
      transaction_hash: 'Transaction Hash',
      view_on_etherscan: 'View on Etherscan',
      block_hash: 'Block Hash',
      network: 'Network',
      currency: 'Currency',
      block_confirmation: 'Block Confirmation',
      block_transaction_time: 'Block Transaction Time',
      block_number: 'Block No.',
      address_information: 'Address Information',
      address: 'Address',
      direction: 'Direction',
      post_balance_of_wallet_address: 'Post Balance of Wallet Address',
      display_name: 'Display Name',
      client_id: 'Client ID',
      wallet_tag: 'Wallet Tag',
    },
  },

  report: {
    daily: 'Daily',
    custom_periodic: 'Custom Periodic',
    summary: 'Summary',

    dailyTab: {
      date: 'Date',
      address: 'Address',
      network: 'Network',
      token: 'Token',
      address_group: 'Address Group',
      display_name: 'Display Name',
      opening_balance: 'Opening Balance',
      in_amount: 'In Amount',
      out_amount: 'Out Amount',
      tx_fee: 'TX Fee',
      closing_balance: 'Closing Balance',
    },
    customPeriodicTab: {
      date: 'Date',
      address: 'Address',
      network: 'Network',
      token: 'Token',
      address_group: 'Address Group',
      display_name: 'Display Name',
      opening_balance: 'Opening Balance',
      in_amount: 'In Amount',
      out_amount: 'Out Amount',
      tx_fee: 'TX Fee',
      closing_balance: 'Closing Balance',
    },
    summaryTab: {
      date: 'Date',
      network: 'Network',
      token: 'Token',
      address_group: 'Address Group',
      opening_balance: 'Opening Balance',
      in_amount: 'In Amount',
      out_amount: 'Out Amount',
      tx_fee: 'TX Fee',
      closing_balance: 'Closing Balance',
    },
  },
};

export default json;

import {
  Direction,
  RiskLevel,
  TransferTransactionStatus
} from '@wallet-manager/node-types/dist/src/postgres/const';

import { TransactionGetAllRes } from '../../../../api/report/transaction/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import useGetNetworkInfo from '../../../../hooks/useGetNetworkInfo';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export const useTransformData = () => {
  const { te, en_te, tb, en_tb } = useTranslation();

  const { getNetworkDisplayName } = useGetNetworkInfo();

  const mappingFn = (mode: 'table' | 'export') => (item: TransactionGetAllRes) => {
    const teByMode = mode === 'export' ? en_te : te;
    const tbByMode = mode === 'export' ? en_tb : tb;

    const toDisplayAmount = displayAmountCurrying(item.asset.decimal);

    const mapping: Array<[string, unknown]> = [
      ['rawData', item],
      [TRANSLATE_KEY.transactionTime, toDisplayTime(item.transDate)],
      [
        TRANSLATE_KEY.status,
        teByMode('enumTransferTransactionStatus', TransferTransactionStatus[item.status]),
      ],

      [TRANSLATE_KEY.displayName, item.inspectionWallet.walletName],
      [TRANSLATE_KEY.addressGroup, item.inspectionWallet.inspectionWalletGroup.groupName],
      [TRANSLATE_KEY.address, item.walletAddress],
      [
        TRANSLATE_KEY.network,
        getNetworkDisplayName({ chainId: item.chainId, chainType: item.chainType }),
      ],
      [TRANSLATE_KEY.token, item.assetName],
      [TRANSLATE_KEY.direction, teByMode('enumDirection', Direction[item.direction])],

      [TRANSLATE_KEY.amount, toDisplayAmount(item.amount)],
      [TRANSLATE_KEY.riskLevel, teByMode('enumRiskLevel', RiskLevel[item.riskLevel])],
      [TRANSLATE_KEY.sendingAddress, item.fromAddress],
      [TRANSLATE_KEY.receivingAddress, item.toAddress],
      [TRANSLATE_KEY.minerFee, toDisplayAmount(item.transFee)],
      [TRANSLATE_KEY.postBalance, toDisplayAmount(item.postBalance)],
      [TRANSLATE_KEY.feeRecord, tbByMode(item.isFee)],
      [TRANSLATE_KEY.checkpointId, item.checkPointId],
      [TRANSLATE_KEY.certifiedWithOnChainBalance, tbByMode(item.checkPointCertified)],
      [TRANSLATE_KEY.transactionHash, item.txHash],

      [TRANSLATE_KEY.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      [TRANSLATE_KEY.creationTime, toDisplayTime(item.createdDate)],
      [TRANSLATE_KEY.settlementTime, toDisplayTime(item.walletSettlementDate)],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({
    translatePrefix: TRANSLATE_PREFIX,
    mappingFn,
  });

  return { transformData };
};

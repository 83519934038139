import { useEffect, useState } from 'react';

import AddressGroupSelection from '../../../../common/components/Filter/Selection/AddressGroupSelection';
import NetworkSelection from '../../../../common/components/Filter/Selection/NetworkSelection';
import TokenSelection from '../../../../common/components/Filter/Selection/TokenSelection';
import { MpTextField, MpTextFieldNumberOnly } from '../../../../components';
import CheckboxWithLabel from '../../../../components/CheckboxWithLabel';
import { useDatePicker } from '../../../../components/DatePicker';
import GeneralSelection from '../../../../components/Selection/GeneralSelection';
import { useTranslation } from '../../../../hooks';
import useGetSerachParams from '../../../../hooks/useGetSerachParams';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';
import { EnumBalanceOperator, FilterFieldsState } from '../types';

export const initialFields = {
  chainCode: '',
  // chainCodes: [],
  displayName: '',
  address: '',
  addressGroupNames: [],
  tokens: [],
  assetBalanceMode: '',
  assetBalanceAmount: '',
  assetBalanceExcludeZero: false,
  settlementDateFrom: '',
  settlementDateTo: '',
};

function useFilter() {
  const { searchParams, clearSearchParams } = useGetSerachParams(['address', 'addressGroupName']);

  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const DateFields = { SettlementTime: useDatePicker() };

  const [fields, setFields] = useState<FilterFieldsState>({
    ...initialFields,
    address: searchParams.address,
    addressGroupNames: searchParams.addressGroupName ? [searchParams.addressGroupName] : [],
  });

  useEffect(() => {
    setFields((prev) => ({
      ...prev,
      settlementDateFrom: DateFields.SettlementTime.start,
      settlementDateTo: DateFields.SettlementTime.end,
    }));
  }, [DateFields.SettlementTime.start, DateFields.SettlementTime.end]);

  const fieldArr = [
    {
      name: t(TRANSLATE_KEY.address),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.address) })}
          value={fields.address}
          onChange={(e) => setFields((prev) => ({ ...prev, address: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.displayName),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.displayName) })}
          value={fields.displayName}
          onChange={(e) => setFields((prev) => ({ ...prev, displayName: e.target.value }))}
        />
      ),
    },

    {
      name: t(TRANSLATE_KEY.network),
      // name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.network) }),
      component: (
        <NetworkSelection
          mode={'single'}
          value={fields.chainCode}
          onChange={(chainCode) => {
            setFields((prev) => ({
              ...prev,
              chainCode,
              tokens: [],
              assetBalanceAmount: '',
              assetBalanceMode: '',
            }));
          }}
          clearSelect={() => {
            setFields((prev) => ({
              ...prev,
              chainCode: '',
              tokens: [],
              assetBalanceAmount: '',
              assetBalanceMode: '',
            }));
          }}
        />
        // <GeneralSelection
        //   mode={'multiple'}
        //   label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
        //   value={fields.chainCodes}
        //   onChange={(chainCodeArr) => {
        //     setFields((prev) => ({ ...prev, chainCodes: chainCodeArr }));
        //   }}
        //   enumData={clientSupportedAssetChainCodeMapping}
        //   isSearchable
        //   nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
        //   isNoSorting
        // />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.token) }),
      component: (
        <TokenSelection
          mode={'multiple'}
          chainCode={fields.chainCode}
          value={fields.tokens}
          onChange={(tokens) => {
            setFields((prev) => ({
              ...prev,
              tokens,
              assetBalanceAmount: '',
              assetBalanceMode: tokens.length === 1 ? EnumBalanceOperator.EqualTo : '',
            }));
          }}
        />
        // <GeneralSelection
        //   mode={'multiple'}
        //   label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
        //   value={fields.chainCodes}
        //   onChange={(chainCodeArr) => {
        //     setFields((prev) => ({ ...prev, chainCodes: chainCodeArr }));
        //   }}
        //   enumData={clientSupportedAssetChainCodeMapping}
        //   isSearchable
        //   nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
        //   isNoSorting
        // />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.addressGroup) }),
      component: (
        <AddressGroupSelection
          mode={'multiple'}
          value={fields.addressGroupNames}
          onChange={(value) => setFields((prev) => ({ ...prev, addressGroupNames: value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.addressBalance),
      component: (
        <div style={{ display: 'flex', gap: '20px' }}>
          <GeneralSelection
            mode={'single'}
            label={''}
            enumTranslatePrefix={'enumMathComparison'}
            enumData={EnumBalanceOperator}
            value={fields.assetBalanceMode}
            onChange={(value) => setFields((prev) => ({ ...prev, assetBalanceMode: value }))}
            clearSelect={() => {}}
            sx={{ formControl: { flex: 1.5 } }}
            disabled={!fields.chainCode || fields.tokens.length !== 1}
          />
          <MpTextFieldNumberOnly
            sx={{ flex: 2 }}
            label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.amount) })}
            value={fields.assetBalanceAmount}
            onChange={(e) => setFields((prev) => ({ ...prev, assetBalanceAmount: e.target.value }))}
            disabled={!fields.chainCode || fields.tokens.length !== 1}
          />

          <CheckboxWithLabel
            id={'asset-amount-exclude-zero'}
            label={t(TRANSLATE_KEY.excludeZero)}
            value={fields.assetBalanceExcludeZero}
            onChange={(value) => {
              setFields((prev) => ({ ...prev, assetBalanceExcludeZero: value }));
            }}
            style={{ container: { flex: 1 } }}
          />
        </div>
      ),
    },
    {
      name: t(TRANSLATE_KEY.settlementTime),
      component: <DateFields.SettlementTime.Picker type="dateTime" />,
    },
  ];

  const onResetFields = () => {
    setFields(initialFields);
    DateFields.SettlementTime.clearDate();
    clearSearchParams();

    return initialFields;
  };

  return {
    fieldArr,
    fields,
    onResetFields,
  };
}

export default useFilter;

import { ReportDailyTabGetAllRes } from '../../../../../api/report/report/types';
import { useListMappingTransform, useTranslation } from '../../../../../hooks';
import useGetNetworkInfo from '../../../../../hooks/useGetNetworkInfo';
import { displayAmountCurrying, toDisplayDate } from '../../../../../utils';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export const useTransformData = () => {
  const { t, tc, tb } = useTranslation('enumConstants');

  const { getNetworkDisplayName } = useGetNetworkInfo();

  const mappingFn = (_mode: 'table' | 'export') => (item: ReportDailyTabGetAllRes) => {
    const toDisplayAmount = displayAmountCurrying(item.asset.decimals);

    const mapping: Array<[string, unknown]> = [
      ['rawData', item],
      [TRANSLATE_KEY.date, toDisplayDate(item.markDate)],
      [TRANSLATE_KEY.displayName, item.inspectionWallet.walletName],
      [TRANSLATE_KEY.addressGroup, item.inspectionWallet.inspectionWalletGroup.groupName],
      [TRANSLATE_KEY.address, item.walletAddress],
      [
        TRANSLATE_KEY.network,
        getNetworkDisplayName({ chainId: item.chainId, chainType: item.chainType }),
      ],
      [TRANSLATE_KEY.token, item.assetName],
      [TRANSLATE_KEY.openingBalance, toDisplayAmount(item.openingBalance)],
      [TRANSLATE_KEY.inAmount, toDisplayAmount(item.inAmount)],
      [TRANSLATE_KEY.outAmount, toDisplayAmount(item.outAmount)],
      [TRANSLATE_KEY.txFee, toDisplayAmount(item.transFee)],
      [TRANSLATE_KEY.closingBalance, toDisplayAmount(item.closingBalance)],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({
    translatePrefix: TRANSLATE_PREFIX,
    mappingFn,
  });

  return { transformData };
};

import { useState } from 'react';

import AddressGroupSelection from '../../../../common/components/Filter/Selection/AddressGroupSelection';
import NetworkSelection from '../../../../common/components/Filter/Selection/NetworkSelection';
import { MpTextField } from '../../../../components';
import { useTranslation } from '../../../../hooks';
import { selectAddressGroup } from '../../../../reducer/addressGroupSlice';
import { selectAsset } from '../../../../reducer/assetSlice';
import { useAppSelector } from '../../../../reducer/hooks';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';
import { FilterFieldsState } from '../types';

export const initialFields = {
  chainCode: '',
  // chainCodes: [],
  displayName: '',
  address: '',
  addressGroupNames: [],
};

function useFilter() {
  const { clientSupportedAssetChainCodeMapping } = useAppSelector(selectAsset);
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);

  const { t, tc } = useTranslation(TRANSLATE_PREFIX);

  const [fields, setFields] = useState<FilterFieldsState>(initialFields);

  const fieldArr = [
    {
      name: t(TRANSLATE_KEY.displayName),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.displayName) })}
          value={fields.displayName}
          onChange={(e) => setFields((prev) => ({ ...prev, displayName: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.address),
      component: (
        <MpTextField
          label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.address) })}
          value={fields.address}
          onChange={(e) => setFields((prev) => ({ ...prev, address: e.target.value }))}
        />
      ),
    },
    {
      name: t(TRANSLATE_KEY.network),
      // name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.network) }),
      component: (
        <NetworkSelection
          mode={'single'}
          value={fields.chainCode}
          onChange={(chainCode) => {
            setFields((prev) => ({ ...prev, chainCode }));
          }}
          clearSelect={() => {
            setFields((prev) => ({ ...prev, chainCode: '' }));
          }}
        />
        // <GeneralSelection
        //   mode={'multiple'}
        //   label={tc('phSelection', { fieldName: t(TRANSLATE_KEY.network) })}
        //   value={fields.chainCodes}
        //   onChange={(chainCodeArr) => {
        //     setFields((prev) => ({ ...prev, chainCodes: chainCodeArr }));
        //   }}
        //   enumData={clientSupportedAssetChainCodeMapping}
        //   isSearchable
        //   nameFn={(name) => CHAIN_CODE_NETWORK_DISPLAY_NAME_MAPPING[name as ChainCode]}
        //   isNoSorting
        // />
      ),
    },
    {
      name: tc('multiple_selections_label', { fieldName: t(TRANSLATE_KEY.addressGroup) }),
      component: (
        <AddressGroupSelection
          mode={'multiple'}
          value={fields.addressGroupNames}
          onChange={(value) => setFields((prev) => ({ ...prev, addressGroupNames: value }))}
        />
      ),
    },
  ];

  const onResetFields = () => {
    setFields(initialFields);

    return initialFields;
  };

  return { fieldArr, fields, onResetFields };
}

export default useFilter;

import { AddressBalanceGetAllRes } from '../../../../api/addressManagement/addressBalance/types';
import { useListMappingTransform, useTranslation } from '../../../../hooks';
import useGetNetworkInfo from '../../../../hooks/useGetNetworkInfo';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from '../constants';

export const useTransformData = () => {
  const { tb, en_tb } = useTranslation('enumConstants');

  const { getNetworkDisplayName } = useGetNetworkInfo();

  const mappingFn = (mode: 'table' | 'export') => (item: AddressBalanceGetAllRes) => {
    const tbByMode = mode === 'export' ? en_tb : tb;

    const settlementTime =
      item.transferTransaction && toDisplayTime(item.transferTransaction.walletSettlementDate);

    const toDisplayAmount = displayAmountCurrying(item.asset.decimal);

    const mapping: Array<[string, unknown]> = [
      ['rawData', item],
      [TRANSLATE_KEY.displayName, item.inspectionWallet.walletName],
      [TRANSLATE_KEY.addressGroup, item.inspectionWallet.inspectionWalletGroup.groupName],
      [
        TRANSLATE_KEY.network,
        getNetworkDisplayName({ chainId: item.chainId, chainType: item.chainType }),
      ],
      [TRANSLATE_KEY.token, item.assetName],
      [TRANSLATE_KEY.address, item.walletAddress],
      [TRANSLATE_KEY.addressBalance, toDisplayAmount(item.balance)],
      [TRANSLATE_KEY.latestCheckpointId, item.latestCheckPointId],
      [TRANSLATE_KEY.latestCheckedBlockNumber, item.latestCheckPointBlockNumber],
      [TRANSLATE_KEY.certifiedWithOnChainBalance, tbByMode(item.isCheckPointCertified)],
      [TRANSLATE_KEY.lastCertifiedCheckpointId, item.lastCertifiedCheckPointId],

      [TRANSLATE_KEY.creationTime, toDisplayTime(item.createdDate)],
      [TRANSLATE_KEY.lastModifiedTime, toDisplayTime(item.lastModifiedDate)],
      [TRANSLATE_KEY.settlementTime, settlementTime],
    ];

    return mapping;
  };

  const transformData = useListMappingTransform({
    translatePrefix: TRANSLATE_PREFIX,
    mappingFn,
  });

  return { transformData };
};

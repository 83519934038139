import LoginForm from './LoginForm';
import Logo from './Logo';

interface IProps {
  submitBtn: { wording: string; onSubmit: () => Promise<void>; autoSubmit?: boolean };
  onBackBtnClick?: () => void;
  hintWording?: string;
  children: React.ReactNode;
}

const PageWrapper = (props: IProps) => {
  const { submitBtn, onBackBtnClick, children, hintWording = 'try notice wording' } = props;

  return (
    <>
      <div className="login-page">
        <div className="content">
          <Logo />

          <LoginForm
            title={'Wallet Tracking'}
            subtitle={hintWording}
            submitBtn={submitBtn}
            onBackBtnClick={onBackBtnClick}
          >
            {children}
          </LoginForm>
        </div>
      </div>
    </>
  );
};

export default PageWrapper;

import './styles/index.css';

import { useState } from 'react';
import { useQuery } from 'react-query';

import { InspectionWalletFeatureCode } from '@wallet-manager/node-types';

import APIs from '../../../api';
import CustomDataGrid from '../../../components/Layout/CustomDataGrid';
import Filter from '../../../components/Layout/Filter';
import PageTopBar from '../../../components/Layout/PageTopBar';
import { removeEmptyStringApiParams } from '../../../helper';
import { useAlerting, useTranslation } from '../../../hooks';
import useGetNetworkInfo from '../../../hooks/useGetNetworkInfo';
import useLoading from '../../../hooks/useLoading';
import useRefreshPage from '../../../hooks/useRefreshPage';
import { selectAddressGroup } from '../../../reducer/addressGroupSlice';
import { useAppSelector } from '../../../reducer/hooks';
import { selectIsMobileView } from '../../../reducer/mediaSlice';
import {
  amountMultipleDecimals,
  downloadFiles,
  getFullApiResponse
} from '../../../utils';
import { EXPORT_FILE_NAME, OMIT_KEYS, TRANSLATE_PREFIX } from './constants';
import useFilter from './hooks/useFilter';
import useTableColumns from './hooks/useTableColumns';
import { useTransformData } from './hooks/useTransformData';
import { ApiParamsState, FilterFieldsState } from './types';

function AddressBalance() {
  const isMobile = useAppSelector(selectIsMobileView);
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { merchantSupportedAssetChainCodeMapping, getAssetDecimal } = useGetNetworkInfo();
  const { addressGroupNameMapping } = useAppSelector(selectAddressGroup);

  const { fieldArr, fields, onResetFields } = useFilter();

  const [apiParams, setApiParams] = useState<ApiParamsState>(getApiParams());

  const { refreshDependency, getNewRefreshDependency } = useRefreshPage();

  const [page, setPage] = useState(0);

  const { alerting } = useAlerting();

  const { columns } = useTableColumns();

  const { transformData } = useTransformData();

  const queryRes = useQuery({
    queryKey: [TRANSLATE_PREFIX, apiParams, page, refreshDependency],
    queryFn: () => {
      showLoading(TRANSLATE_PREFIX);

      const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

      return APIs.addressManagement.addressBalance.getAll({ ...removedEmptyStringParams, page });
    },
    onSettled: () => {
      hideLoading(TRANSLATE_PREFIX);
    },
  });

  const { rows = [], count = 0 } = queryRes.data || {};

  const content = transformData({ data: rows, mode: 'table' });

  const onExport = async () => {
    if (count === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const removedEmptyStringParams = removeEmptyStringApiParams(apiParams);

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.addressManagement.addressBalance.getExport(
        { ...removedEmptyStringParams, page, pageSize },
        { signal }
      );
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = OMIT_KEYS.export;

    const res = transformData({ mode: 'export', data: rawRes, omitKeys });
    const config = {};
    downloadFiles(EXPORT_FILE_NAME, res, config);
  };

  function getApiParams(overrideFields?: FilterFieldsState): ApiParamsState {
    const {
      address: walletAddress,
      // chainCodes,
      chainCode,
      displayName: walletName,
      tokens: assetNameList,
      addressGroupNames,
      assetBalanceAmount,
      assetBalanceMode: balanceOperator,
      assetBalanceExcludeZero: excludeZero,
      settlementDateFrom,
      settlementDateTo,
    } = overrideFields || fields;

    const walletGroupIdList = addressGroupNames.map(
      (addressGroupName) => addressGroupNameMapping[addressGroupName].id
    );

    const { chainId = '', chainType = '' } =
      merchantSupportedAssetChainCodeMapping[chainCode] || {};

    const getBalance = () => {
      if (!chainCode || assetNameList.length !== 1 || !assetBalanceAmount) {
        return '';
      }

      const { chainId, chainType } = merchantSupportedAssetChainCodeMapping[chainCode];

      const targetAssetName = assetNameList[0];

      const decimal = getAssetDecimal({ chainId, chainType, assetName: targetAssetName });

      return amountMultipleDecimals(assetBalanceAmount, decimal);
    };

    const balance = getBalance();

    return {
      walletGroupIdList,
      walletAddress,
      walletName,
      chainId: chainId,
      chainType: String(chainType),
      assetNameList,
      balance,
      balanceOperator,
      excludeZero,
      settlementDateFrom,
      settlementDateTo,
    };
  }

  const onApply = () => {
    const apiParams = getApiParams();

    setApiParams(apiParams);

    getNewRefreshDependency();

    setPage(0);
  };

  const onClear = () => {
    const initialFields = onResetFields();

    const initialApiParams = getApiParams(initialFields);

    setApiParams(initialApiParams);
  };

  const getIsFiltering = () =>
    Object.values(apiParams).some((value) => (Array.isArray(value) ? value.length : value));

  const isFiltering = getIsFiltering();

  return (
    <div style={{ padding: isMobile ? '0 1rem' : undefined }}>
      <PageTopBar
        buttonConfigArr={[
          {
            width: '125px',
            node: tc('export'),
            onClick: onExport,
            permission: InspectionWalletFeatureCode.AddressManagement.AddressBalance.Export,
          },
        ]}
      />

      <Filter isFiltering={isFiltering} fieldArr={fieldArr} onApply={onApply} onClear={onClear} />
      <CustomDataGrid
        rows={content}
        rowCount={count}
        columns={columns}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        isLoading={isLoading}
      />
    </div>
  );
}

export default AddressBalance;

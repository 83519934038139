import { TransferTransactionStatus } from '@wallet-manager/node-types/dist/src/postgres/const';

import { TransactionDetailsRes } from '../../../../../../../api/report/transaction/types';
import { ReactComponent as ArrowIcon } from '../../../../../../../assets/icons/TransactionDetails/arrow.svg';
import { ReactComponent as CopyIcon } from '../../../../../../../assets/icons/TransactionDetails/copy.svg';
import CopyableText from '../../../../../../../components/CopyableText';
import { displayAmountWithCurrency } from '../../../../../../../helper';
import { useTranslation } from '../../../../../../../hooks';
import useGetNetworkInfo from '../../../../../../../hooks/useGetNetworkInfo';
import { useAppSelector } from '../../../../../../../reducer/hooks';
import { selectIsMobileView } from '../../../../../../../reducer/mediaSlice';
import {
  displayAmountCurrying,
  toDisplayTime
} from '../../../../../../../utils';
import { TRANSLATE_PREFIX } from '../../../../constants';
import LabeledDataContainer from '../../components/LabeledDataContainer';
import { TRANSLATE_KEY } from '../constants';

function TransactionDetailsSection({ data }: { data: TransactionDetailsRes }) {
  const { t, te } = useTranslation(TRANSLATE_PREFIX);
  const isMobile = useAppSelector(selectIsMobileView);

  const { getNetworkDisplayName } = useGetNetworkInfo();

  const networkDisplayName = getNetworkDisplayName({
    chainId: data.chainId,
    chainType: data.chainType,
  });

  const toDisplayAmount = displayAmountCurrying(data.asset.decimal);

  const transactionAmount = toDisplayAmount(data.amount);

  const transactionAmountWithCurrency = displayAmountWithCurrency(
    transactionAmount,
    data.assetName
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <div style={{ fontSize: '14px', fontWeight: '700' }}>
        {t(TRANSLATE_KEY.transactionDetails)}
      </div>

      <LabeledDataContainer
        configArr={[
          { label: TRANSLATE_KEY.creationTime, data: toDisplayTime(data.createdDate) },
          { label: TRANSLATE_KEY.settlementTime, data: toDisplayTime(data.walletSettlementDate) },
          { label: TRANSLATE_KEY.minerFee, data: toDisplayAmount(data.transFee) },
          {
            label: TRANSLATE_KEY.status,
            data: te('enumTransferTransactionStatus', TransferTransactionStatus[data.status]),
            isColored: true,
          },
        ]}
      />

      <div
        style={{
          backgroundColor: '#333333',
          padding: '12px 24px',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
            fontSize: '14px',
          }}
        >
          <div>{networkDisplayName}</div>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              justifyContent: isMobile ? undefined : 'flex-end',
            }}
          >
            <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.transactionAmount)}</div>
            <div>{transactionAmountWithCurrency}</div>
          </div>
        </div>

        <hr style={{ opacity: 0.2, borderBottomWidth: '0px', margin: '12px 0px' }} />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div
            style={{
              fontSize: '12px',
              gap: '16px',
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: isMobile ? '100%' : '1fr 0fr 1fr',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.send)}</div>

              <CopyableText
                text={data.fromAddress}
                iconWithTooltip={<CopyIcon />}
                sx={{ fontSize: '10px' }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ArrowIcon style={{ transform: isMobile ? 'rotate(90deg)' : undefined }} />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <div style={{ opacity: 0.5 }}>{t(TRANSLATE_KEY.receive)}</div>
              <CopyableText
                text={data.toAddress}
                iconWithTooltip={<CopyIcon />}
                sx={{ fontSize: '10px' }}
              />
            </div>
          </div>

          {data.toWalletTag && (
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <div
                style={{
                  backgroundColor: '#222222',
                  padding: '0px 4px',
                  border: '1px solid rgba(255,255,255,0.1)',
                  borderRadius: '6px',
                  height: '16px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: 1,
                }}
              >
                <div style={{ fontSize: '10px', opacity: 0.5 }}>{data.toWalletTag}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransactionDetailsSection;

import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import APIs from '../../api';
import SearchBar from '../../components/Layout/SearchBar';
import { useTranslation } from '../../hooks';
import useLoading from '../../hooks/useLoading';
import { useAppSelector } from '../../reducer/hooks';
import { selectIsMobileView } from '../../reducer/mediaSlice';
import { TRANSLATE_KEY, TRANSLATE_PREFIX } from './constants';
import SummaryBlock, { SummaryBlockProps } from './SummaryBlock';

function Home() {
  const isMobile = useAppSelector(selectIsMobileView);
  const [searchFields, setSearchFields] = useState({ input: '', filter: 'addressList' });

  const redirect = useNavigate();
  const { t, tc } = useTranslation(TRANSLATE_PREFIX);
  const { showLoading, hideLoading } = useLoading();

  const onSearch = () => {
    redirect(searchFields.filter + `?search=${encodeURI(searchFields.input)}`);
  };

  const { data } = useQuery({
    queryKey: [TRANSLATE_PREFIX],
    queryFn: () => {
      showLoading(TRANSLATE_PREFIX);
      return APIs.query.dashboard.getDashboard();
    },
    onSettled: () => hideLoading(TRANSLATE_PREFIX),
  });

  const getDate = (isoTimeStr: string | undefined) => {
    if (!isoTimeStr) {
      return '';
    }

    return isoTimeStr.split('T')[0];
  };

  const getUtcNoticeText = (date: string) => {
    if (!date) {
      return undefined;
    }

    return t(TRANSLATE_KEY.utcNoticeText);
  };

  const getTransactionWidgetRedirectPath = () => {
    if (!displayLastSettlementDate) {
      return 'transaction';
    }

    return `transaction?settlementDate=${displayLastSettlementDate}`;
  };

  const displayLastSettlementDate = getDate(data?.transferTransactionDate);
  const displayReportDate = getDate(data?.reportDate);

  const summaryBlockArr: SummaryBlockProps[] = [
    {
      title: t(TRANSLATE_KEY.totalAddress),
      redirectPath: 'addressList',
      data: data?.addressCount,
    },
    {
      title: t(TRANSLATE_KEY.totalAddressGroup),
      redirectPath: 'addressGroup',
      data: data?.walletGroupCount,
    },
    {
      title: t(TRANSLATE_KEY.dailySettlementCount),
      redirectPath: getTransactionWidgetRedirectPath(),
      data: data?.transferTransactionCount,
      extraData: displayLastSettlementDate,
      noticeText: getUtcNoticeText(displayLastSettlementDate),
    },
    {
      title: t(TRANSLATE_KEY.lastDailyReport),
      redirectPath: 'report',
      data: displayReportDate,
      noticeText: getUtcNoticeText(displayReportDate),
    },
  ];

  return (
    <div
      style={{
        paddingRight: isMobile ? '1rem' : undefined,
        paddingLeft: isMobile ? '1rem' : undefined,
      }}
    >
      <SearchBar
        label={tc('phInputField', { fieldName: t(TRANSLATE_KEY.addressNameOrAddress) })}
        onSearch={onSearch}
        input={{
          value: searchFields.input,
          setValue: (value) => setSearchFields((prev) => ({ ...prev, input: value })),
        }}
        filter={{
          items: {
            transaction: 'transaction',
            address_list: 'addressList',
            address_group: 'addressGroup',
            report: 'report',
          },
          value: searchFields.filter,
          setValue: (value) => setSearchFields((prev) => ({ ...prev, filter: value })),
        }}
      />

      <div
        style={{
          marginTop: '24px',
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: `calc(353px * 2 + 12px)`,
          gap: '12px',
        }}
      >
        {summaryBlockArr.map((props, index) => (
          <SummaryBlock key={uniqueId(`dashboard-${index}`)} {...props} />
        ))}
      </div>
    </div>
  );
}

export default Home;
